<script lang="ts" setup>
  import type { MessagePayload, MessagePayloadGaEvent } from './COrderingAppPanel.types'

  const {
    public: { orderingAppHost }
  } = useRuntimeConfig()
  const orderingStore = useOrderingStore()
  const { iframeUrl, closePanel, orderingAppNavTo } = useOrderingAppNavigation()
  const {
    isPanelOpen,
    hasPanelMounted,
    isPanelLoading,
    routeChanged,
    iframeLoaded,
    animationDurationElapsed,
    animationDuration
  } = useOrderingPanelState()
  usePreventBodyOverflow(isPanelOpen, 'ordering-panel')

  const emit = defineEmits<{
    (event: 'message-signed-in-state', isSignedIn: boolean): void
    (event: 'message-cart-item-count', cartItemCount: number): void
    (event: 'message-did-sign-up', closeOrderingPanel: boolean): void
    (event: 'message-did-sign-in', closeOrderingPanel: boolean): void
    (event: 'message-did-sign-out', closeOrderingPanel: boolean): void
  }>()

  const orderingAppIFrame = ref<HTMLIFrameElement | null>(null)
  const allow = computed(() => {
    return `geolocation ${orderingAppHost}; payment ${orderingAppHost}`
  })

  watch(routeChanged, () => {
    if (routeChanged.value === true && isPanelOpen.value === true) {
      // Reset the iframe src to force a reload
      setIframeSrc()
    }
  })

  onMounted(() => {
    window.removeEventListener('message', onMessageFromIFrame)
    window.addEventListener('message', onMessageFromIFrame)

    if (orderingAppIFrame.value) {
      isPanelLoading.value = true

      orderingAppIFrame.value.onload = () => {
        iframeLoaded.value = true
        checkLoadingStatus()
      }
      setIframeSrc()
    }
    hasPanelMounted.value = true
  })

  function setIframeSrc() {
    if (orderingAppIFrame.value) {
      try {
        animationDurationElapsed.value = false
        isPanelLoading.value = true
        iframeLoaded.value = false

        setTimeout(() => {
          animationDurationElapsed.value = true
          checkLoadingStatus()
        }, animationDuration)
        const url = new URL(iframeUrl.value)
        orderingAppIFrame.value.src = url.href
      } catch (error) {
        console.error('Error setting iframe src:', error)
      }
    } else {
      console.warn('Ordering iFrame is not mounted')
    }
    routeChanged.value = false
  }

  function checkLoadingStatus() {
    if (iframeLoaded.value && animationDurationElapsed.value) {
      isPanelLoading.value = false
    }
  }

  function handleAccountClick() {
    if (orderingStore.user.isSignedIn) {
      orderingAppNavTo('/profile&source=marketing-site')
    } else {
      orderingAppNavTo('/sign-in&source=marketing-site&journeyType=marketing_site_panel_button')
    }
  }

  /**
   * @description Listen for and handle messages from the ordering app
   * @param event {MessageEvent} - The message event
   */
  function onMessageFromIFrame(event: MessageEvent): void {
    if (event.origin !== orderingAppHost) {
      return
    }

    if (typeof event.data !== 'string') {
      console.warn('Received message from ordering app with invalid data:', event.data)
      return
    }

    const eventData = JSON.parse(event.data)
    if (
      !Object.hasOwn(eventData, 'key') &&
      eventData.key !== 'GRILLD_ORDERING_APP_EVENT' &&
      eventData.key !== 'GRILLD_GA_EVENT'
    ) {
      console.warn('Received message from ordering app with invalid key:', eventData.key)
      return
    }

    function handleMessageEvent(payload: MessagePayload) {
      switch (payload.op) {
        case 'signed_in_state':
          orderingStore.user.isSignedIn = payload.value
          emit('message-signed-in-state', payload.value)
          break
        case 'cart_item_count':
          orderingStore.cartItemCount = payload.value
          break
        case 'user_tags':
          orderingStore.user.tags = payload.value
          break
        case 'did_sign_up_from_website':
          emit('message-did-sign-up', payload.value)
          break
        case 'did_sign_in_from_website':
          emit('message-did-sign-in', payload.value)
          break
        case 'did_sign_out_from_website':
          emit('message-did-sign-out', payload.value)
          break
        case 'order_at_table_error_dismissed':
          closePanel()
          break
        default:
          break
      }
    }
    function handleGaEvent(payload: MessagePayloadGaEvent) {
      const { trackOrderingAppEvent } = useAnalytics()
      trackOrderingAppEvent(payload.op, payload.value as Record<string, any>)
    }

    if (eventData.key === 'GRILLD_GA_EVENT') {
      const payload = JSON.parse(event.data) as MessagePayloadGaEvent
      handleGaEvent(payload)
    } else if (eventData.key === 'GRILLD_ORDERING_APP_EVENT') {
      const payload = JSON.parse(event.data) as MessagePayload
      handleMessageEvent(payload)
    }
  }
</script>

<template>
  <transition name="fade">
    <div v-if="isPanelOpen === true" class="overlay-mask" />
  </transition>

  <aside
    :class="[
      'iframe-panel',
      {
        'slide-in': isPanelOpen === true,
        'slide-out': isPanelOpen === false
      }
    ]"
  >
    <CNavbarPanel :is-signed-in="orderingStore.user.isSignedIn" @account="handleAccountClick" @close="closePanel" />

    <div v-show="isPanelLoading" class="loading-frame">
      <CPageWelcome :pause-animation="!isPanelLoading" />
    </div>
    <iframe v-show="!isPanelLoading" ref="orderingAppIFrame" :allow="allow" title="ordering app" />
  </aside>
</template>

<style lang="scss" scoped>
  .overlay-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000aa;
    z-index: 800;
  }

  .loading-frame {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - var(--navbar-height)); // view height - panel navbar height
    background-color: var(--color-base-red);
  }

  .iframe-panel {
    z-index: 1000;
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;

    transform: translateX(100%);

    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;

    background-color: var(--color-base-paper);

    iframe {
      height: calc(100dvh - var(--navbar-height)); // dynamic view height - header height
      width: 100%;
      border: none;
      background: var(--color-secondary-paper-light);
    }

    @include screenMdAndUp {
      width: 576px;
    }
  }

  /* Animations */

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  @keyframes slide-in {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes slide-out {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .slide-in {
    animation: 0.5s forwards slide-in;
  }

  .slide-out {
    animation: 0.5s forwards slide-out;
  }
</style>
