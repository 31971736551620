<script lang="ts" setup>
  import AsyncToast from '@/components/common/AsyncToast'

  const iframeRef = useOrderingPanelRef()
  const { panelMounted } = useOrderingPanelState()

  const { handlePostSignOut } = usePostSignOut()
  const { handlePostSignIn, showSignedInToast } = usePostSignIn()

  /**
   * @description Handle the post sign in actions
   */
  function usePostSignIn() {
    const { closePanel } = useOrderingAppNavigation()
    const showSignedInToast = ref<boolean>(false)

    /**
     * @description Handle the post sign in actions
     * @param closeOrderingPanel {boolean} - Determines if the panel should close
     * @returns void
     */
    function handlePostSignIn(closeOrderingPanel: boolean) {
      if (closeOrderingPanel) {
        closePanel()
      }

      showSignedInToast.value = true
    }

    return { showSignedInToast, handlePostSignIn }
  }

  /**
   * @description Handle the post sign out actions
   */
  function usePostSignOut() {
    const { closePanel } = useOrderingAppNavigation()

    /**
     * @description Handle the post sign out actions
     * @param closeOrderingPanel {boolean} - Determines if the panel should close
     * @returns void
     */
    function handlePostSignOut(closeOrderingPanel: boolean) {
      if (closeOrderingPanel) {
        closePanel()
      }
    }

    return { handlePostSignOut }
  }
</script>

<template>
  <NuxtLayout name="default">
    <!-- pass through the page content in the default slot -->
    <slot />

    <!-- add in the ordering app -->
    <template #extras>
      <client-only>
        <COrderingAppPanel
          v-if="panelMounted"
          :ref="iframeRef"
          @message-did-sign-up="handlePostSignIn"
          @message-did-sign-in="handlePostSignIn"
          @message-did-sign-out="handlePostSignOut"
        />
      </client-only>

      <AsyncToast :show="showSignedInToast" text="Welcome back to Grill’d!" @close="showSignedInToast = false" />
    </template>
  </NuxtLayout>
</template>
